import { SQLBatch } from '@ht-lib/preproduction-models'
import { Market } from '@ht-lib/preproduction-models'

export interface Job {
  jobId: string
  accountId: string
  isBatch: boolean
  jobInfo: ImageSetInfo
}

interface JobImage {
  imageId: number
  guid: string
}

export interface ImageSetInfo {
  schoolID: number
  schoolName: string
  photographers: PhotographerWithClasses[]
}

interface BatchImage {
  imageId: number
  imageRef: string
  institute: string
  instituteId: number
  photographer: string
  photographerId: number
  collection: string
  collectionId: number
}

export interface BatchImageSet extends SQLBatch {
  images: BatchImage[]
}

export interface Image {
  id: number
  ref: string
}

export interface Photographer {
  id: number
  name: string
}

export interface Institute {
  id: number
  name: string
}

export interface Collection {
  id: number
  name: string
}

export interface PhotographerWithClasses extends Photographer {
  classes: ClassWithImages[]
}

export interface ClassWithImages extends Institute {
  images: JobImage[]
}

export interface GridImage {
  image: Image
  market: Market
  institute: Institute
  collection: Collection
  photographer: Photographer
}

export function flattenBatch (batchSet: BatchImageSet): GridImage[] {
  return batchSet.images.map(image => {
    return {
      image: {
        id: image.imageId,
        ref: image.imageRef
      },
      market: batchSet.market,
      institute: {
        id: image.instituteId,
        name: image.institute
      },
      collection: {
        id: image.collectionId,
        name: image.collection
      },
      photographer: {
        id: image.photographerId,
        name: image.photographer
      }
    }
  })
}

export function flattenJob (imgSet: ImageSetInfo): GridImage[] {
  return imgSet.photographers
    .map(photographer => photographer.classes
      .map(clazz => clazz.images
        .map(image => ({
          image: {
            id: image.imageId,
            ref: image.guid
          },
          market: 'S' as Market,
          photographer: {
            id: photographer.id,
            name: photographer.name
          },
          collection: {
            id: clazz.id,
            name: clazz.name
          },
          institute: {
            id: imgSet.schoolID,
            name: imgSet.schoolName
          }
        }))
      ))
    .flat(2)
}
