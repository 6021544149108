import { ImageEntry } from '@ht-lib/accounts-models'
import Axios from 'axios'
import { DRGB, RGB } from '../cccolor'
import { ImageWithMeta, preloadImageXHR } from '../util'
import { Collection, Institute, Photographer } from './job'


export interface Identity {
  nexusId: number
  market: Market
}
type Market = 'S' | 'G'
export interface RawImageMeta {
  AccountCode: string
  CCModelID: string
  CCVal: string
  Class: string
  ID: string
  JobCode: string
  JobSubTypeID: string
  Market: Market
  Orientation: string
  Reference: string
  ShotTypeID: string
  r: string
  g: string
  b: string
  top: string
  left: string
}

export interface ImageMeta extends RGB {
  AccountCode: string
  CCModelID: string
  CCVal: number
  Class: string
  // ID: number
  JobCode: string
  Author?: string
  JobSubTypeID: number
  Orientation: number
  Reference: string
  ShotTypeID: number
  top: number
  left: number
  isPreferred: boolean
}

function imageMetaFromRaw (raw: ImageEntry): ImageMeta {
  const cc = raw.colourCorrection
  return {
    AccountCode: raw.accountCode,
    Author: cc?.author || '',
    CCModelID: cc?.modelId || '',
    CCVal: cc?.value || 0,
    Class: raw.className || '',
    JobCode: raw.jobCode || 'No Job Code',
    JobSubTypeID: raw.jobSubTypeId || 0,
    Orientation: raw.orientation,
    Reference: raw.ref,
    ShotTypeID: raw.shotTypeId,
    R: cc?.r || 0,
    G: cc?.g || 0,
    B: cc?.b || 0,
    top: raw.nudge?.top || 0,
    left: raw.nudge?.left || 0,
    isPreferred: raw.isPreferred
  }
}

interface NudgeValues {
  left: number
  top: number
}

interface CachedImage {
  blob: Blob
  url: string
  width: number
  height: number
}

type LookupReturnType<T> = T extends Identity ? ImageEntry | null : T extends Identity[] ? ImageEntry[] : never

export class CorrectionImage {
  public static async create (name: string, nexusID: number, market: Market, institute: Institute, collection: Collection, photographer: Photographer, meta: ImageEntry, { width = 0, height = 0 }): Promise<CorrectionImage> {
    const url = this.url(name, { width, height }, market !== 'G')
    const preloadedImage = await preloadImageXHR(url)
    return new CorrectionImage(
      name,
      nexusID,
      market,
      institute,
      collection,
      photographer,
      {
        ...preloadedImage,
        meta
      }
    )
  }

  public static async lookup<T extends Identity | Identity[]> (lookup: T): Promise<LookupReturnType<T>> {
    const res = await Axios.post<LookupReturnType<T>>(
      process.env.VUE_APP_IMAGE_LOOKUP,
      lookup,
      {
        params: {
          key: process.env.VUE_APP_API_KEY
        }
      }
    )

    return res.data
  }

  public static url (name: string, { width = 0, height = 0 }, crop: boolean): string {
    return `${ process.env.VUE_APP_IMAGEPREP }/${ name }/prepare?x=${ width }&y=${ height }&needscc=${ true }&nexuscrop=${ crop }`
  }

  public readonly name: string
  public readonly nexusID: number
  public readonly market: Market
  public readonly meta: ImageMeta
  public readonly initial: DRGB
  public actual: DRGB
  public temp: DRGB
  public currentNudge: NudgeValues
  public tempNudge: NudgeValues
  public readonly thumb: CachedImage
  private image: CachedImage | undefined
  private _objectURL: string = ''
  public readonly institute: Institute
  public readonly collection: Collection
  public readonly photographer: Photographer

  private constructor (name: string, nexusID: number, market: Market, institute: Institute, collection: Collection, photographer: Photographer, preloadedImage: ImageWithMeta) {
    this.name = name
    this.nexusID = nexusID
    this.market = market
    this.collection = collection
    this.institute = institute
    this.photographer = photographer
    this.thumb = {
      blob: preloadedImage.blob,
      height: preloadedImage.el.height,
      width: preloadedImage.el.width,
      url: preloadedImage.url
    }
    this.meta = imageMetaFromRaw(preloadedImage.meta)
    this.initial = new DRGB({ D: this.meta.CCVal, R: this.meta.R, G: this.meta.G, B: this.meta.B })
    this.actual = new DRGB().assign(this.initial)
    this.temp = new DRGB().assign(this.initial)

    this.currentNudge = { left: this.meta.left, top: this.meta.top }
    this.tempNudge = { left: this.meta.left, top: this.meta.top }
  }

  public hardReset (): void {
    this.actual = this.initial.clone()
    this.temp = this.actual.clone()
  }

  public resetTemp (): void {
    this.temp = new DRGB()
  }

  public tempToActual (): void {
    this.temp.assign(this.actual)
  }

  public resetNudge (): void {
    this.tempNudge.left = this.currentNudge.left
    this.tempNudge.top = this.currentNudge.top
  }

  public saveNudge (): void {
    this.currentNudge.left = this.tempNudge.left
    this.currentNudge.top = this.tempNudge.top
  }


  public get canvasDRGB (): DRGB {
    return DRGB.subtract(this.temp, this.initial)
  }

  public get correctionDRGB (): DRGB {
    return DRGB.subtract(this.actual, this.initial)
  }

  public applyTemp (): DRGB {
    this.actual.assign(this.temp)
    return this.actual
  }

  public toString (): string {
    return `ID:${ this.name } Initial: ${ this.initial.toString() } Actual: ${ this.actual } Offset: ${ this.temp }`
  }

  public clearOffset (): void {
    this.temp.subtract(this.temp)
  }

  public get isModified (): boolean {
    return !DRGB.equal(this.temp, this.actual) || this.nudgeChanged()
  }

  public nudgeChanged (): boolean {
    return this.currentNudge.left !== this.tempNudge.left || this.currentNudge.top !== this.tempNudge.top
  }

  public url ({ width = 0, height = 0 }): string {
    return CorrectionImage.url(this.name, { width, height }, this.market !== 'G')
  }

  public async getImage ({ width = 0, height = 0 }): Promise<CachedImage> {
    if (this.image) {
      return this.image
    }

    const url = this.url({ width, height })
    const preloadedImage = await preloadImageXHR(url)
    this.image = {
      blob: preloadedImage.blob,
      height: preloadedImage.el.height,
      width: preloadedImage.el.width,
      url: preloadedImage.url
    }

    return this.image
  }
}
