import { FlagForEditing } from '@ht-lib/preproduction-models'
import { Market } from '@ht-lib/accounts-models'

export async function flagForEditing (longRefs: string[], flagText: string, market: Market, batchId?: number | string): Promise<boolean> {
  const req: FlagForEditing = {
    batchId: typeof batchId === 'string' ? parseInt(batchId) : batchId,
    longRefs,
    flagText,
    market
  }

  console.log('Sending flag for edting request', req)
  const url = `${ process.env.VUE_APP_CC_API }/api/contrastcorrection/batch/flag?key=${ process.env.VUE_APP_API_KEY }`
  const res = await fetch(url, {
    method: 'PATCH',
    body: JSON.stringify(req),
    headers: [['Content-type', 'application/json']]
  })

  return res.status === 200
}
