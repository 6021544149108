
import QSlider from 'quasar/src/components/slider/QSlider.js';
import { SLIDER_MIN, SLIDER_MAX } from '@/main'

export default QSlider.extend({
  props: {
    min: { default: SLIDER_MIN },
    max: { default: SLIDER_MAX },
    step: { default: 1 },
    snap: { default: true },
    label: { default: true }
  }
})
