import store from '@/store'
import axios, { AxiosRequestConfig } from 'axios'
import { ApproveEntry } from '@ht-lib/preproduction-models'
import { CorrectionImage } from './models/image'


const approveStatus = 55

export enum Type {
  Batch = 'batch',
  Job = 'job'
}

export enum ApproveType {
  FAILED,
  PARTIAL,
  SUCCESS
}

export interface ApproveOutcome {
  message: string
  type: ApproveType
}

interface ApproveMessage {
  entries: ApproveEntry[]
}

export interface ApproveResponse {
  success: boolean
  message: string
}

export function approveJob (images: CorrectionImage[], accountID: string, jobID: string): Promise<ApproveOutcome> {
  const url = `${ process.env.VUE_APP_ACCOUNTS_API }/accounts/${ accountID }/jobs/${ jobID }/status`
  const key = process.env.VUE_APP_ACCOUNTS_API_KEY
  return approve(images, url, key, approveStatus, Type.Job)
}

export function approveBatch (images: CorrectionImage[], batchID: number): Promise<ApproveOutcome> {
  const url = `${ process.env.VUE_APP_BATCH_API }/batch/${ batchID }/updateStatus`
  const key = process.env.VUE_APP_BATCH_API_KEY
  return approve(images, url, key, approveStatus, Type.Batch)
}

async function approve (images: CorrectionImage[], url: string, key: string, status: number, type: Type): Promise<ApproveOutcome> {
  const imageRes = await approveImages(images)

  if (!imageRes.success) {
    return {
      message: imageRes.message,
      type: ApproveType.FAILED
    }
  }

  try {
    const config: AxiosRequestConfig = { params: { key, status } }
    const res = await axios.put<ApproveResponse>(url, undefined, config)

    console.log(imageRes, res)
    if (res.data.success) return {
      message: res.data.message,
      type: ApproveType.SUCCESS
    }

    return {
      message: `Images in the ${ type } were updated. But the ${ type } status was not. The ${ type } status may already have progressed`,
      type: ApproveType.PARTIAL
    }
  } catch (e) {
    return {
      message: e,
      type: ApproveType.FAILED
    }
  }
}

export async function approveImages (images: CorrectionImage[]): Promise<ApproveResponse> {
  const { displayName } = store.state.profile
  try {
    const entries: ApproveEntry[] = images.map(x => ({
      author: displayName,
      name: x.name,
      imageID: x.nexusID,
      imageNexusID: x.nexusID,
      predictValue: x.initial.D,
      CCModelID: x.meta.CCModelID,
      market: x.market,
      adjustedValue: x.actual.D,
      r: x.actual.R,
      g: x.actual.G,
      b: x.actual.B,
      top: x.currentNudge.top,
      left: x.currentNudge.left
    }))

    const url = `${ process.env.VUE_APP_CC_API }/api/contrastcorrection/approve`
    const key = process.env.VUE_APP_API_KEY
    const config: AxiosRequestConfig = { params: { key } }
    const data: ApproveMessage = { entries }

    const res = await axios.put<ApproveResponse>(url, data, config)

    return res.data
  } catch (e) {
    return {
      message: e,
      success: false
    }
  }
}
