




































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { CorrectionImage as CorrectionImageClass } from '../models/image'
import AdjustSliders from '@/components/AdjustmentSliders.vue'
import CorrectionImage from '@/components/CorrectionImage.vue'
import ImageCanvas from '@/components/ImageCanvas.vue'
import { DRGB } from '../cccolor'
import { SLIDER_MIN, SLIDER_MAX } from '@/main'
import { mapState } from 'vuex'

@Component({
  components: { AdjustSliders, CorrectionImage, ImageCanvas },
  computed: { ...mapState('profile/settings', ['showContrastBadgesToggle']) }
})
export default class extends Vue {
  @Prop() images!: CorrectionImageClass[]
  readonly initial: DRGB = new DRGB({ D: 0, R: 0, G: 0, B: 0 })
  min: DRGB = new DRGB()
  max: DRGB = new DRGB()
  value = this.initial.clone()

  hasChanged () {
    return !DRGB.equal(this.value, this.initial)
  }

  @Watch('value', { immediate: false, deep: true })
  valueUpdated () {
    if (this.initial.D === 0) console.log('0')
    this.images.forEach(img => {
      img.temp.assign(DRGB.add(img.actual, this.value))
    })
  }

  closeMulti () {
    this.images.forEach(img => {
      img.tempToActual()
    })
    this.$emit('close')
  }

  created () {
    type MinMaxer = (...values: number[]) => number
    const reducer = (fn: MinMaxer, array: DRGB[]) =>
      array.reduce((prev, curr) => (new DRGB({
        D: fn(prev.D, curr.D),
        R: fn(prev.R, curr.R),
        G: fn(prev.G, curr.G),
        B: fn(prev.B, curr.B)
      })))

    const corrections = this.images.map(x => x.actual)
    this.min.assign(reducer(Math.min, corrections)).transform(-SLIDER_MIN).scale(-1)
    this.max.assign(reducer(Math.max, corrections)).transform(-SLIDER_MAX).scale(-1)

    console.log(`Min: ${ this.min } Max: ${ this.max.toString() }`)
  }

  reset () {
    this.value = this.initial.clone()
  }

  submit () {
    this.$emit('corrected', this.value)
    this.$emit('close')
  }
}
