








































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator'
import { CorrectionImage } from '@/models/image'
import ImageCanvas from '@/components/ImageCanvas.vue'
import AdjustmentSliders from '@/components/AdjustmentSliders.vue'
import { DRGB } from '../cccolor'

@Component({
  components: { ImageCanvas, AdjustmentSliders }
})
export default class extends Vue {
  @Prop() image!: CorrectionImage
  @Prop() height!: number
  imageBlob: Blob | null = null
  loading: boolean = true
  minHeight = '500px'


  get defaultShowRGB (): boolean {
    return this.image !== undefined && this.image.market === 'G'
  }

  @Watch('image.nexusID', { immediate: true })
  async updateImage () {
    //Set back to loading
    this.loading = true
    this.imageBlob = null

    const image = await this.image.getImage({ width: 1800, height: 1100 })
    this.imageBlob = image.blob
    this.loading = false
  }

  imageLoaded () {
    this.loading = false
    this.$nextTick(() => {
      this.minHeight = this.$el.clientHeight + 'px'
    })
  }

  reset () {
    this.image.resetNudge()
    this.image.hardReset()
  }

  @Emit('closed')
  close () {
    this.$emit('image-updated', this.image, true)
  }

  beforeDestroy () {
    this.$emit('image-updated', this.image, true)
  }
}
