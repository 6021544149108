





























import { Component, Emit, Vue } from 'vue-property-decorator'
import { GridImage } from '@/models/job'
import SubToolbar from '@/components/SubToolbar.vue'
import QA from '@/pages/QA.vue'
import { CorrectionImage } from '@/models/image'
import { ApproveOutcome, ApproveType, Type, approveImages, ApproveResponse } from '@/approve'
import { toTitleCase } from '../util'
import { BannerModel } from '../components/Banner.vue'
import { mapState } from 'vuex'

type CloseFunction = () => Promise<void>
type ApproveFunction = () => Promise<ApproveOutcome>
type FlagForEditFunction = (longRefs: string[], flagText: string) => Promise<boolean>
type DataFunction = () => Promise<void>
type SaveFunction = () => Promise<ApproveResponse>
type ImageNotFoundFunction = () => Promise<void>

@Component({
  components: { SubToolbar, 'qa': QA },
  computed: { ...mapState('profile', ['uid', 'displayName']) }
})
export default class extends Vue {
  type!: Type
  closeFn!: CloseFunction
  approveFn!: ApproveFunction
  saveFn: SaveFunction = () => approveImages(this.adjustedImages)
  dataFn!: DataFunction
  flagFn!: FlagForEditFunction
  imageNotFoundFn!: ImageNotFoundFunction
  jobImages: GridImage[] | null = null
  longRefs: string[] = []
  saveLoading = false
  approveLoading = false
  flagLoading = false
  flagText = ''
  banner: BannerModel = {}
  adjustedImages: CorrectionImage[] = []
  toolbarTitle = ''
  readOnly = false
  timeStarted: number = 0
  hasImages = false

  get loading () {
    return this.approveLoading || this.saveLoading
  }

  imagesLoaded (longRefs: string[]) {
    this.timeStarted = new Date().getTime()
    console.log('Time Started')
    this.hasImages = true
    console.log('Images loaded: ', longRefs)
    this.longRefs = longRefs
  }

  async loadData () {
    try {
      await this.dataFn()
    } catch (e) {
      this.updateBannerRedirect('negative', `Failed to find ${ this.type }: ${ e }`)
    }
  }

  close () {
    this.$q.dialog({
      title: `Leaving ${ toTitleCase(this.type) }`,
      message: `You are about to close this ${ this.type }, any changes you have made will be lost!`,
      cancel: true
    }).onOk(this.closeFn)
  }

  approve () {
    this.$q.dialog({
      title: `Approve ${ toTitleCase(this.type) }`,
      message: `You are about to approve this ${ this.type }, are you sure?`,
      cancel: true
    }).onOk(async () => {
      try {
        this.approveLoading = true
        const res = await this.approveFn()

        switch (res.type) {
          case ApproveType.FAILED: {
            this.updateBanner('negative', `Error approving ${ this.type }: ${ res.message }`)
            break
          }
          case ApproveType.PARTIAL: {
            this.updateBannerRedirect('warning', res.message)
            break
          }
          case ApproveType.SUCCESS: {
            this.updateBannerRedirect('positive', res.message)
            break
          }
        }
      } catch (e) {
        console.error(e)
        this.updateBanner('negative', `Error approving ${ this.type }: ${ e }`)
      } finally {
        this.approveLoading = false
      }
    })
  }

  flagImages (inLongRefs?: string[]) {
    this.flagText = `${ (this as any).displayName } - `
    const longRefs = inLongRefs || this.longRefs
    this.$q.dialog({
      title: `Flagging Images`,
      prompt: {
        model: this.flagText,
        type: 'text'
      },
      message: `You are about to mark ${ longRefs.length } images to be flagged for image editing. Clicking yes will send the job/batch images to the editing team. Do you want to continue?`,
      cancel: true
    }).onOk(async () => {
      this.flagLoading = true
      const success = await this.flagFn(longRefs, this.flagText)
      if (!success) {
        this.updateBanner('negative', `Error flagging images for editing. The server logs will have more details`)
      } else {
        this.updateBannerRedirect('positive', "Images have been flagged")
        this.$router.push('/')
      }
      this.flagLoading = false
    })
  }

  save () {
    this.$q.dialog({
      title: `Saving ${ toTitleCase(this.type) }`,
      message: `You are about to save this ${ this.type }, this will preserve any corrections made but will not progress the ${ this.type } on to the print queue, are you sure?`,
      cancel: true
    }).onOk(async () => {
      this.saveLoading = true
      const res = await this.saveFn()
      if (res.success) {
        this.updateBannerRedirect('positive', res.message)
      } else {
        this.updateBanner('negative', `Error saving ${ this.type }: ${ res.message }`)
      }
      this.saveLoading = false
    })
  }

  updateBanner (color: BannerModel['color'], message: BannerModel['message'], action?: BannerModel['action']) {
    this.$set(this.banner, 'color', color)
    this.$set(this.banner, 'message', message)
    if (action) {
      this.$set(this.banner, 'action', action)
    }
  }

  @Emit('banner')
  updateBannerRedirect (color: BannerModel['color'], message: BannerModel['message']): BannerModel {
    this.$router.push('/')
    return { color, message }
  }

  imageAdjusted (image: CorrectionImage) {
    const index = this.adjustedImages.indexOf(image)
    if (index === -1) {
      this.adjustedImages.push(image)
    } else {
      this.adjustedImages.splice(index, 1, image)
    }
  }

  async imageNotFound () {
    await this.imageNotFoundFn()
    this.updateBannerRedirect('warning', `There are some images missing from this ${ this.type }, they have been scheduled for upload. Please check back soon.`)
  }
}
