const pixelChannelValueLookup = [710, 658, 604, 583, 572, 562, 552, 544, 537, 530, 523, 517, 510, 504, 498, 493, 488, 484, 480, 475, 472, 468, 465, 461, 458, 455, 452, 449, 446, 443, 441, 438, 435, 433, 431, 429, 426, 424, 422, 420, 418, 416, 414, 412, 411, 409, 407, 405, 404, 402, 400, 399, 397, 396, 394, 392, 391, 390, 388, 387, 386, 384, 383, 382, 380, 379, 378, 377, 376, 374, 373, 372, 371, 370, 369, 368, 367, 366, 365, 364, 363, 362, 361, 360, 359, 358, 357, 356, 355, 354, 353, 352, 351, 350, 349, 348, 347, 346, 345, 344, 343, 342, 341, 340, 339, 338, 337, 336, 335, 334, 333, 332, 331, 330, 329, 328, 327, 326, 325, 324, 323, 322, 321, 320, 319, 317, 317, 316, 314, 314, 312, 312, 311, 309, 309, 307, 307, 306, 304, 304, 302, 302, 301, 299, 299, 297, 297, 296, 294, 294, 292, 292, 291, 290, 289, 287, 287, 286, 285, 284, 283, 282, 281, 280, 279, 278, 277, 276, 275, 274, 273, 272, 271, 270, 269, 267, 267, 266, 264, 263, 262, 261, 260, 259, 258, 257, 256, 255, 254, 253, 252, 251, 249, 248, 247, 246, 245, 244, 242, 241, 240, 239, 237, 236, 235, 234, 232, 231, 229, 228, 227, 225, 224, 222, 221, 219, 217, 216, 214, 212, 211, 209, 207, 205, 203, 201, 199, 197, 195, 193, 190, 188, 185, 183, 180, 178, 175, 172, 168, 165, 162, 159, 155, 152, 148, 145, 141, 137, 132, 127, 120, 111, 100, 87, 67, 26]
const sigmoidOffset = 5.256
const sigmoidSlope = 60

export interface RGB {
  R: number
  G: number
  B: number
}

export class DRGB implements RGB {
  public static add (a: DRGB, b: DRGB): DRGB {
    const c = a.clone()
    c.D += b.D
    c.R += b.R
    c.G += b.G
    c.B += b.B
    return c
  }

  public static equal (a: DRGB, b: DRGB): boolean {
    return a.D === b.D && DRGB.rgbEqual(a, b)
  }

  public static rgbEqual (a: DRGB, b: DRGB): boolean {
    return a.R === b.R && a.G === b.G && a.B === b.B
  }

  public static subtract (a: DRGB, b: DRGB): DRGB {
    const c = a.clone()
    c.D -= b.D
    c.R -= b.R
    c.G -= b.G
    c.B -= b.B
    return c
  }

  public D: number
  public R: number
  public G: number
  public B: number

  public constructor ({ D = 0, R = 0, G = 0, B = 0 } = {}) {
    this.D = D
    this.R = R
    this.G = G
    this.B = B
  }

  public add (a: DRGB): DRGB {
    this.D += a.D
    this.R += a.R
    this.G += a.G
    this.B += a.B
    return this
  }

  public subtract (a: DRGB): DRGB {
    this.D -= a.D
    this.R -= a.R
    this.G -= a.G
    this.B -= a.B
    return this
  }

  public transform (x: number): DRGB {
    this.D += x
    this.R += x
    this.G += x
    this.B += x
    return this
  }

  public scale (x: number): DRGB {
    this.D *= x
    this.R *= x
    this.G *= x
    this.B *= x
    return this
  }

  public clone (): DRGB {
    return new DRGB({ D: this.D, R: this.R, G: this.G, B: this.B })
  }

  public assign (drgb: DRGB): DRGB {
    this.D = drgb.D
    this.R = drgb.R
    this.G = drgb.G
    this.B = drgb.B
    return this
  }

  public toString (): string {
    return `D: ${ this.D } R:${ this.R } G:${ this.G } B:${ this.B }`
  }
}

export function correctContrast (source: ImageData, target: DRGB): ImageData {
  const correction = target.clone()

  correction.R *= -1
  correction.G *= -1
  correction.B *= -1

  correction.R += correction.D
  correction.G += correction.D
  correction.B += correction.D

  const newPixels = new ImageData(source.width, source.height)
  const stride = 4

  for (let i = 0; i < source.data.length; i += stride) {
    const R = i
    const G = i + 1
    const B = i + 2
    const A = i + 3

    newPixels.data[R] = applyContrastToPixel(source.data[R], correction.R)
    newPixels.data[G] = applyContrastToPixel(source.data[G], correction.G)
    newPixels.data[B] = applyContrastToPixel(source.data[B], correction.B)
    newPixels.data[A] = source.data[A]
  }

  return newPixels
}

function applyContrastToPixel (pixelChannelValue: number, contrastCorrectionValue: number): number {
  let adjustmentIndex = pixelChannelValueLookup[pixelChannelValue] + contrastCorrectionValue
  adjustmentIndex = clampTo(adjustmentIndex, 0, 720)
  return inverseSigmoid(adjustmentIndex)
}

function clampTo (value: number, min: number, max: number): number {
  if (value < min) {
    return min
  } else if (value > max) {
    return max
  }
  return value
}

function inverseSigmoid (val: number): number {
  return (255 * ((-1) / (1 + Math.exp((sigmoidOffset - ((1 / sigmoidSlope) * val)))))) + 255
}
