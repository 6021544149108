


















import Banner from '@/components/Banner.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  components: { Banner },
  computed: { ...mapState(['profile']) }
})
export default class extends Vue {
  @Prop() title!: string
  @Prop() banner!: Banner
  @Prop() disableClose!: boolean
  @Prop() disableApprove!: boolean
  @Prop({default:false}) flagLoading!: boolean
  @Prop({ default: false }) approveLoading!: boolean
  @Prop({ default: false }) saveLoading!: boolean

  onShowOnlyPreferredImagesToggle (value: boolean) {
    return this.$store.dispatch('profile/settings/showOnlyPreferredImagesToggled', value)
  }
}
