






































































import { Component, Vue, Ref, Prop, Watch } from 'vue-property-decorator'
import ImageView from '@/components/ImageView.vue'
import MultiSelectBox from '@/components/MultiSelectBox.vue'
import MultiAdjust from '@/layouts/MultiAdjust.vue'
import CorrectionImage from '@/components/CorrectionImage.vue'
import { CorrectionImage as CorrectionImageClass, Identity } from '@/models/image'
import { Photographer, Institute, ImageSetInfo, GridImage } from '@/models/job'
import { DRGB } from '../cccolor'
import { ErrorTypes } from '@/util'
import { KeyBindings } from '../store/profile/settings'

@Component({
  components: { ImageView, MultiSelectBox, MultiAdjust, CorrectionImage }
})
export default class extends Vue {
  @Prop() dark!: boolean
  @Prop() job!: ImageSetInfo
  @Ref() multiSelection!: Vue
  @Prop() jobImages!: GridImage[]
  images: CorrectionImageClass[] = []
  imagesLoaded = 0
  modalShown = false
  modalImageIndex = -1
  modalImage: CorrectionImageClass | null = null
  modalPhotographer: Photographer | null = null
  modalClass: Institute | null = null
  gridImageHeight = 200
  imgHeight = 500
  multiDialogShown = false
  multiDialogImages: CorrectionImageClass[] | null = null
  selected: number[] = []
  keyDownListener!: (ev: KeyboardEvent) => void

  mounted () {
    this.keyDownListener = (ev) => {
      const keybindings = this.$store.state.profile.settings.keyBindings as KeyBindings
      switch (ev.key.toLowerCase()) {
        case keybindings.NextImage.toLowerCase(): {
          this.nextImage()
          break
        }
        case keybindings.PreviousImage.toLowerCase(): {
          this.prevImage()
          break
        }
      }
    }

    window.addEventListener('keydown', this.keyDownListener)
  }

  beforeDestroy () {
    window.removeEventListener('keydown', this.keyDownListener)
  }

  get shouldFilter (): boolean {
    return this.$store.state.profile.settings.showOnlyPreferredImagesToggle
  }

  get filteredImages () {
    const images = this.shouldFilter
      ? this.images.filter(i => i.meta.isPreferred)
      : this.images
    return images
  }

  @Watch('shouldFilter')
  shouldFilterChanged () {
    console.log('shouldFilter changed, resetting selected indexes.')
    this.modalShown = false
    this.modalImageIndex = -1
    this.modalImage = null
    this.modalPhotographer = null
    this.modalClass = null
    this.multiDialogShown = false
    this.multiDialogImages = null
    this.selected = []
  }

  getClassName (img: CorrectionImageClass, i: number): string {
    let str = ""
    if (this.isSelected(i)) {
      str += 'bg-primary selected'
    } else if (img.meta.CCModelID === '') {
      str += 'no-correction'
    } else if (img.meta.Author) {
      str += 'corrected '
    }

    return str
  }

  get hasImages (): boolean {
    return this.images.length !== 0
  }

  flagImages () {
    const filtered = this.filteredImages
    this.$emit('images-flagged', this.selected.map(x=> filtered[x].name))
  }

  onSelect (itemIndex: number) {
    // console.log('onSelect', itemIndex)
    if (!this.selected.includes(itemIndex)) {
      this.selected.push(itemIndex)
    }
  }

  zeroAll () {
    this.$q.dialog({
      title: 'Zero all selected images?',
      cancel: true,
      message: 'You are about to zero all of the selected images, are you sure?'
    }).onOk(() => {
      const filtered = this.filteredImages
      this.selected.forEach(i => {
        const image = filtered[i]
        image.temp = new DRGB()
        this.updateImage(i)
      })
    })
  }

  onUnselect (itemIndex: number) {
    // console.log('onUnselect', itemIndex)
    const selectedIndex = this.selected.indexOf(itemIndex)
    if (selectedIndex >= 0) {
      this.selected.splice(selectedIndex, 1)
    }
  }

  async created () {
    console.log('DDPI', window.devicePixelRatio)

    const identities: Identity[] = this.jobImages.map(x => ({ market: x.market, nexusId: x.image.id }))
    const lookups = await CorrectionImageClass.lookup(identities)
    const missingImages = this.jobImages.filter(ji => !lookups.find(li => (li.nexusId === ji.image.id)))
    if (missingImages.length) {
      console.error('Missing Images: ', missingImages)
      this.$emit('image-not-found')
      return
    }

    const correctionImages = this.jobImages.map(async x => {
      try {
        const meta = lookups.find(y => y.nexusId === x.image.id)
        if (typeof meta === 'undefined') throw Error(`Unexpected lookup error with Image: ${ JSON.stringify(x) }`)

        const image = await CorrectionImageClass.create(
          meta.longRef,
          x.image.id,
          x.market,
          x.institute,
          x.collection,
          x.photographer,
          meta,
          { height: this.gridImageHeight }
        )

        this.$emit('image-adjusted', image)
        this.$emit('loading-status', `${ ++this.imagesLoaded } / ${ this.jobImages.length }`)
        if (this.imagesLoaded === this.jobImages.length) {
          this.$emit('loaded')
        }
        return image
      } catch (e) {
        if (e === ErrorTypes.ImageNotFound) {
          this.$emit('image-not-found')
        } else {
          console.error(e)
        }
      }
    })

    try {
      const results = await Promise.all(correctionImages)
      if (results.some(x => x === undefined)) {
        throw Error('Some images were not loaded')
      }

      this.images = results as CorrectionImageClass[]
      this.$emit('images-loaded', this.images.map(x => x.name))
    } catch (e) {
      this.$emit('failure', e)
    }
  }

  get left () {
    const i = this.modalImageIndex - 1
    return i >= 0 ? this.filteredImages[i] : null
  }

  get right () {
    const i = this.modalImageIndex + 1
    return i < this.filteredImages.length ? this.filteredImages[i] : null
  }

  goRight () {
    this.imageUpdated(this.filteredImages[this.modalImageIndex], false)
    this.modalImageIndex += 1
    this.modalImage = this.filteredImages[this.modalImageIndex]
  }

  goLeft () {
    this.imageUpdated(this.filteredImages[this.modalImageIndex], false)
    this.modalImageIndex -= 1
    this.modalImage = this.filteredImages[this.modalImageIndex]
  }

  unselectAll () {
    this.selected.splice(0, this.selected.length)
  }

  modalClosed () {
    this.modalImageIndex = -1
    this.modalShown = false
  }

  isSelected (index: number): boolean {
    return this.selected.includes(index)
  }

  itemDoubleClicked (itemIndex: number) {
    this.imageMeta(this.filteredImages[itemIndex])
    this.modalImageIndex = itemIndex
    this.modalShown = true
  }

  editMultiple () {
    const filteredImages = this.filteredImages
    const images = this.selected.map(i => filteredImages[i])
    this.multiDialogImages = images
    this.multiDialogShown = true

  }

  imageUpdated (image: CorrectionImageClass, closeModal = true) {
    image.saveNudge()
    console.log('updated')
    this.applyImageUpdate(image)
    if (closeModal)
      this.modalClosed()
  }

  applyImageUpdate (image: CorrectionImageClass) {
    image.applyTemp()
    const index = this.images.findIndex(x => x.nexusID === image.nexusID)
    console.log(`Updated Image: ${ image.toString() }`)
    this.$set(this.images, index, image)
    this.$emit('image-adjusted', image)
  }

  updateImage (imageIndex: number) {
    const image = this.filteredImages[imageIndex]
    this.applyImageUpdate(image)
  }

  multiImagesCorrected (offset: DRGB) {
    this.selected.forEach(imageIndex => {
      const image = this.filteredImages[imageIndex]
      image.temp.assign(DRGB.add(offset, image.actual))
      this.updateImage(imageIndex)
    })

    this.unselectAll()
  }

  resetAllAdjustments () {
    // This happens for all images, but only a subset (filteredImages) are be shown to the user
    // I'm going to assume they're okay with "reset all adjustments" happening on hidden images too.
    this.images.forEach((image, i) => {
      image.hardReset()
      this.$set(this.images, i, image)
    })
  }

  checkForUpdateImage () {
    if (this.modalImageIndex !== -1) {
      this.imageUpdated(this.filteredImages[this.modalImageIndex], false)
    }
  }

  prevImage () {
    this.checkForUpdateImage()

    if (this.modalImageIndex <= 0) {
      this.modalImageIndex = this.filteredImages.length - 1
    } else {
      this.modalImageIndex--
    }

    this.itemDoubleClicked(this.modalImageIndex)
  }

  nextImage () {
    this.checkForUpdateImage()

    if (this.modalImageIndex >= this.filteredImages.length - 1) {
      this.modalImageIndex = 0
    } else {
      this.modalImageIndex++
    }

    this.itemDoubleClicked(this.modalImageIndex)
  }

  imageMeta (img: CorrectionImageClass) {
    this.modalPhotographer = img.photographer
    this.modalClass = img.institute
    this.modalImage = img
  }
}
