











import { Component, Vue, Prop, Model } from 'vue-property-decorator'
import Slider from './Slider.vue'
import { DRGB } from '../cccolor'
import { SLIDER_MIN, SLIDER_MAX } from '@/main'

type WheelKey = 'D' | 'R' | 'G' | 'B'
@Component({
  components: { Slider }
})
export default class extends Vue {
  @Prop({ default: () => ({ D: SLIDER_MIN, R: SLIDER_MIN, G: SLIDER_MIN, B: SLIDER_MIN }) }) min!: DRGB
  @Prop({ default: () => ({ D: SLIDER_MAX, R: SLIDER_MAX, G: SLIDER_MAX, B: SLIDER_MAX }) }) max!: DRGB
  @Model() values!: DRGB
  @Prop() rgbVisible!: boolean

  mounted () {
    ((this.$refs.dSlider as Vue).$el as HTMLElement).focus()
  }

  onWheel (key: WheelKey, e: WheelEvent) {
    this.values[key] += Math.sign(-e.deltaY)
  }
}
