











import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { correctContrast } from '@/cccolor'
import { imageFromBlob } from '@/util'
import { CorrectionImage } from '../models/image'
import { mapState } from 'vuex'

@Component({
  computed: { ...mapState('profile/settings', ['showContrastBadgesToggle']) }
})
export default class extends Vue {
  @Prop({ required: true }) image!: Blob
  @Prop() correctionImage!: CorrectionImage
  @Prop() width!: number
  @Prop() height!: number
  imageDataURL: string = ''

  created () {
    this.imageDataURL = URL.createObjectURL(this.image)
  }

  @Watch('correctionImage.actual', { immediate: false, deep: true })
  onCorrectionChanged () {
    this.adjust()
  }

  async adjust () {
    const osr = new OffscreenCanvas(this.width, this.height)
    const osrCtx = osr.getContext('2d') as OffscreenCanvasRenderingContext2D

    osrCtx.drawImage(await imageFromBlob(this.image), 0, 0)
    const imageData = osrCtx.getImageData(0, 0, this.width, this.height)

    const adjusted = correctContrast(imageData, this.correctionImage.correctionDRGB)
    osrCtx.putImageData(adjusted, 0, 0)

    const blob = await osr.convertToBlob({ type: 'image/jpeg', quality: 1 })

    this.imageDataURL = URL.createObjectURL(blob)
  }

  beforeDestroy () {
    URL.revokeObjectURL(this.imageDataURL)
  }
}
