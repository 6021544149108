




import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator'
import { Nudger } from '@ht-lib/canvas-nudge'

import { correctContrast } from '@/cccolor'
import { imageFromBlob } from '@/util'
import { CorrectionImage } from '@/models/image'
import { KeyBindings } from '../store/profile/settings'

@Component
export default class extends Vue {
  @Prop({ required: true }) image!: Blob
  @Prop({ required: true }) correctionImage!: CorrectionImage
  @Prop({ required: true }) nudge!: boolean
  @Ref() canvas!: HTMLCanvasElement
  context!: CanvasRenderingContext2D
  originalImageData!: ImageData
  lastUpdated: number = 0
  nudger!: Nudger
  listener!: (ev: KeyboardEvent) => void

  mounted () {
    this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D
  }

  initialiseNudger (image: HTMLImageElement) {
    //Nudge values are currently always nudge to the left. As all images are stored landscape on the filesystem. top is not used (yet)
    this.nudger = new Nudger(this.canvas, 2, true)
    this.nudger.setImage(image)
    this.nudger.nudgeX(this.correctionImage.currentNudge.left)
    this.nudger.nudgeY(-this.correctionImage.currentNudge.left)
    this.originalImageData = this.nudger.imageData

    const keybindings = this.$store.state.profile.settings.keyBindings as KeyBindings
    this.listener = (ev: KeyboardEvent) => {
      switch (ev.key.toLowerCase()) {
        case keybindings.ShiftUp.toLowerCase(): {
          this.nudger.nudgeY(-1)
          break
        } case keybindings.ShiftDown.toLowerCase(): {
          this.nudger.nudgeY(1)
          break
        } case keybindings.ShiftLeft.toLowerCase(): {
          this.nudger.nudgeX(-1)
          break
        } case keybindings.ShiftRight.toLowerCase(): {
          this.nudger.nudgeX(1)
          break
        }
      }

      this.nudger.render()
    }
    this.listener = this.listener.bind(this)
    window.addEventListener('keydown', this.listener)
    window.addEventListener('keypress', this.listener)

    this.nudger.setCallbacks((x) => {
      this.correctionImage.tempNudge.left = x
    }, (y) => {
      this.correctionImage.tempNudge.left = -y
    })

    this.nudger.render()
  }

  @Watch('correctionImage.nexusID', { immediate: true })
  async updateImage () {
    const image = await imageFromBlob(this.image)
    this.canvas.width = image.width
    this.canvas.height = image.height

    if (this.correctionImage.market === 'G' && this.nudge) {
      this.initialiseNudger(image)
    } else {
      this.context.drawImage(image, 0, 0)
      this.originalImageData = this.context.getImageData(0, 0, image.width, image.height)
    }

    this.adjust()
  }

  @Watch('correctionImage.temp', { immediate: false, deep: true })
  onCorrectionModifierChanged () {
    console.log(`Image Canvas updated: ${ this.correctionImage.toString() }`)

    this.adjust()
  }

  adjust () {
    if (!this.originalImageData) return
    console.log(`Actually Applying to canvas: ${ this.correctionImage.canvasDRGB.toString() }`)
    const adjusted = correctContrast(this.originalImageData, this.correctionImage.canvasDRGB)

    if (this.nudger) {
      this.nudger.updateImageData(adjusted)
    } else {
      this.context.putImageData(adjusted, 0, 0)
    }
  }

  beforeDestroy () {
    if (this.nudger) {
      window.removeEventListener('keydown', this.listener)
      window.removeEventListener('keypress', this.listener)
    }

  }
}
