








import { Component, Vue, Prop } from 'vue-property-decorator'

interface XY { X: number; Y: number }
interface Rect { left: number; top: number; width: number; height: number }

@Component
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop() items!: any[]
  @Prop() selectedIndexes!: number[]
  selectionBoxThreshold = 50
  mouseDown = false
  mouseStart: XY | null = null
  mouseEnd: XY | null = null
  selectedIndexesTemp: number[] = []

  get selectionBox (): Partial<Rect> {
    if (!this.mouseDown || !this.mouseStart || !this.mouseEnd) return {}

    const scroll = this.getScroll()
    const clientRect = this.$el.getBoundingClientRect()

    const left =
      Math.min(this.mouseStart.X, this.mouseEnd.X) - clientRect.left - scroll.x
    const top =
      Math.min(this.mouseStart.Y, this.mouseEnd.Y) - clientRect.top - scroll.y
    const width = Math.abs(this.mouseStart.X - this.mouseEnd.X)
    const height = Math.abs(this.mouseStart.Y - this.mouseEnd.Y)

    return { left, top, width, height }
  }

  get selectionBoxStyling () {
    const style = ({ left = 0, top = 0, width = 0, height = 0 }: Partial<Rect>) => ({
      left: left + 'px',
      top: top + 'px',
      width: width + 'px',
      height: height + 'px'
    })

    if (!this.mouseDown || !this.mouseStart || !this.mouseEnd) { return style({ left: 0, top: 0, width: 0, height: 0 }) } else { return style(this.selectionBox) }
  }

  getScroll () {
    return {
      x:
        this.$el.scrollLeft ||
        document.body.scrollLeft ||
        document.documentElement.scrollLeft,
      y:
        this.$el.scrollTop ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
    }
  }

  onMouseDown (event: MouseEvent) {
    if (event.button === 2) return // Ignore right clicks

    this.selectedIndexesTemp.splice(0, this.selectedIndexesTemp.length)

    this.mouseDown = true
    this.mouseStart = {
      X: event.pageX,
      Y: event.pageY
    }

    window.addEventListener('mousemove', this.onMouseMove)
    window.addEventListener('mouseup', this.onMouseUp)
  }

  onMouseMove (event: MouseEvent) {
    this.mouseEnd = {
      X: event.pageX,
      Y: event.pageY
    }

    const children = Array.from(this.$el.children) as HTMLElement[]
    children
      .slice(0, -1)
      .filter(this.isSelected)
      .forEach(el => this.itemSelected(children.indexOf(el), event))
  }

  isSelected (el: HTMLElement) {
    const selectionBox = this.selectionBox as Required<Rect>
    if (selectionBox.width < this.selectionBoxThreshold && selectionBox.height < this.selectionBoxThreshold) {
      return false
    }

    const elBox = {
      top: el.offsetTop,
      left: el.offsetLeft,
      width: el.clientWidth,
      height: el.clientHeight
    }

    return (
      selectionBox.left <= elBox.left + elBox.width &&
      selectionBox.left + selectionBox.width >= elBox.left &&
      selectionBox.top <= elBox.top + elBox.height &&
      selectionBox.top + selectionBox.height >= elBox.top
    )
  }

  itemClicked (itemIndex: number) {
    // if (!event.metaKey && !event.ctrlKey) {
    //   return this.$emit('itemclicked', itemIndex, event)
    // }

    const selectedIndex = this.selectedIndexes.indexOf(itemIndex)
    const selected = selectedIndex !== -1
    if (selected) {
      this.unSelectItem(itemIndex)
    } else {
      this.selectItem(itemIndex)
    }
  }

  itemSelected (itemIndex: number, event: MouseEvent) {
    if (this.selectedIndexesTemp.indexOf(itemIndex) !== -1) return
    this.selectedIndexesTemp.push(itemIndex)

    const selectedIndex = this.selectedIndexes.indexOf(itemIndex)
    const selected = selectedIndex !== -1

    if (event.altKey) {
      if (selected) {
        this.unSelectItem(itemIndex)
      } else {
        this.selectItem(itemIndex)
      }
    } else if (event.shiftKey) {
      if (selected) this.unSelectItem(itemIndex)
    } else if (!selected) {
      this.selectItem(itemIndex)
    }
  }

  selectItem (itemIndex: number) {
    this.$emit('onselect', itemIndex)
  }

  unSelectItem (itemIndex: number) {
    this.$emit('onunselect', itemIndex)
  }

  onMouseUp () {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)

    this.mouseDown = false
    this.mouseStart = null
    this.mouseEnd = null
  }

  beforeDestroy () {
    window.removeEventListener('mousemove', this.onMouseMove)
    window.removeEventListener('mouseup', this.onMouseUp)
  }
}
